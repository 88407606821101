.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fullHeight {
  height: 100vh;
}

.debugbar {
  position: fixed;
  top: 100vh;
  left: 8px;
  right: 8px;
  bottom: 0;
  box-shadow: 0 0 8 rgba(0, 0, 0, 0.3);
  z-index: 999;
  background: #323232;
  color: white;
  padding: 16px;
  transition: top 0.3s ease-in-out;
}
.debugbar.open {
  top: 40px;
}
.debugbar-handler {
  position: absolute;
  top: -32px;
  left: 16px;
  width: 32px;
  height: 32px;
  background: #323232;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 24px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  transform: rotateZ(-90deg);
}
.debugbar.open .debugbar-handler {
  transform: rotateZ(90deg);
}
.debugbar .title {
  padding: 0 16px 16px;
  margin-top: 0;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #111;
}

.bold {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
}

.thin {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
}

.bodyMeteoTop, .bodyMeteoBottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
}

.topMeteo img {
  text-align: right !important;
  align-items: center;
}

.topMeteo {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.astroFade {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
       animation: fadein 2s;
}

@keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}